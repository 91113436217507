<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <div class="row mb-3">
      <div class="col-12 mt-4 mb-2">
        <h2 class="mb-0">Confirmation commande</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <fieldset class="mb-3">
          <legend>Données du client</legend>
          <div class="card bg-light">
            <div class="card-body">
              <h5 class="card-title color-cordier">{{customer.name}}</h5>
              <p class="card-text">{{customer.address}}<br>{{customer.postalCode}} - {{customer.city}}</p>
              <p class="card-text">{{customer.phone}}</p>
              <p class="card-text text-muted">TVA: {{customer.vat}}</p>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-12 col-sm-6">
        <fieldset class="mb-3">
          <div class="row">
            <div class="col">
              <legend>Signature du client</legend>
            </div>
            <div class="col-auto">
              <button class="btn btn-sm btn-outline-dark" @click="clearCanvas()">Effacer</button>
            </div>
          </div>
          <canvas class="form-control signature-canvas" :class="{ 'border-danger': isCommandInvalid }" id="draw" width="100" height="100"></canvas>
          <div v-if="isCommandInvalid" class="text-danger small">La signature du client est requise</div>
        </fieldset>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <fieldset>
          <legend>Résumé de la commande</legend>
          <div class="row">
            <div class="col-12">
              <table class="table table-striped">
                <thead>
                  <th class="small font-weight-bold bg-light">Référence</th>
                  <th class="small font-weight-bold bg-light weeks_cell">42</th>
                  <th class="small font-weight-bold bg-light weeks_cell">43</th>
                  <th class="small font-weight-bold bg-light weeks_cell">44</th>
                  <th class="small font-weight-bold bg-light weeks_cell">45</th>
                  <th class="small font-weight-bold bg-light weeks_cell">46</th>
                  <th class="small font-weight-bold bg-light weeks_cell">49</th>
                </thead>
                <tbody>
                  <tr v-for="item of orderedArticles" :key="item.articleId">
                    <td class="small">{{item.name}}</td>
                    <td v-for="i of 6" :key="i" class="small weeks_cell">{{item[`qtyWeek${i}`] ? item[`qtyWeek${i}`] : 0}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row bottom-actions w-100">
      <div class="col py-2 d-flex align-items-center">
        <button class="btn btn-dark bg-dark mr-3" @click="handleEditOrder()">Retour commande</button>
        <button class="btn btn-outline-danger mr-3" @click="toggleCancelModal()">Annuler commande</button>
      </div>
      <div class="col py-2 d-flex justify-content-end align-items-center">
        <div class="mr-4">
          <div class="small font-weight-bold text-right">Total articles: {{qtyTotal}}</div>
          <div class="small font-weight-bold text-right">Total ristournes: {{totalRistourne | floatToString('€')}}</div>
        </div>
        <button class="btn btn-danger bg-cordier" @click="handleOrderConfirm()">Confirmer commande</button>
      </div>
    </div>
    <div v-if="showCancelModal" class="overlay">
      <div class="modal rounded p-4">
        <div class="row">
          <div class="col">
            <span class="h3 mb-3 d-block">Êtes-vous sûr ?</span>
            <p class="mb-3 d-block">Cela annulera la commande en cours</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-sm btn-secondary bg-dark mr-3" @click="toggleCancelModal()">Continuer la commande</button>
            <button class="btn btn-sm btn-danger bg-cordier" @click="handleCancel()">Annuler la commande</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';

export default {
  name: 'ConfirmOrder',
  props: ['orderCommand'],
  data() {
    return {
      localOrderCommand: this.orderCommand,
      formatedCommand: null,
      isCommandInvalid: false,
      totalRistourne: null,
      canvas: null,
      ctx: null,
      ctxX: 0,
      ctxY: 0,
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      showCancelModal: false,
      backFromApp: false,
    };
  },
  watch: {
    orderCommand(val) {
      this.localOrderCommand = val;
    },
  },
  methods: {
    draw(e) {
      if (!this.isDrawing) return;
      this.ctx.beginPath();

      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(e.offsetX, e.offsetY);
      this.ctx.stroke();

      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    },
    drawTouch(e) {
      if (!this.isDrawing) return;
      this.ctx.beginPath();

      this.ctx.moveTo(this.lastX, this.lastY);

      this.ctx.lineTo(e.touches[0].clientX - this.ctxX + window.scrollX, e.touches[0].clientY - this.ctxY + window.scrollY);
      this.ctx.stroke();

      [this.lastX, this.lastY] = [e.touches[0].clientX - this.ctxX + window.scrollX, e.touches[0].clientY - this.ctxY + window.scrollY];
    },
    drawInit(e) {
      this.isDrawing = true;
      this.isCommandInvalid = false;
      [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
    },
    drawInitTouch(e) {
      this.isDrawing = true;
      this.isCommandInvalid = false;
      [this.lastX, this.lastY] = [e.touches[0].clientX - this.ctxX + window.scrollX, e.touches[0].clientY - this.ctxY + window.scrollY];
    },
    stopDraw() {
      this.isDrawing = false;
      this.localOrderCommand.signature = this.canvas.toDataURL();
      this.formatedCommand.signature = this.canvas.toDataURL();
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
    handleOrderConfirm() {
      if (this.localOrderCommand.signature !== null) {
        this.$store.commit('pushNewOrder', this.formatedCommand);
        this.$router.replace('/customers');
      }
      this.isCommandInvalid = true;
    },
    handleCancel() {
      this.$store.commit('setWorkingCustomer', null);
      this.$router.replace('/customers');
    },
    handleEditOrder() {
      this.backFromApp = true;
      this.$router.replace({ name: 'new-order', params: { editOrder: this.localOrderCommand } });
    },
    async getTotalRistourne() {
      if (this.isOnline) {
        try {
          const response = await AppService.getRistourne(this.formatedCommand);

          if (response.status === 200) {
            this.totalRistourne = response.data;
          } else {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du total de ristourne.' });
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de la récupération du total de ristourne.' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Nous ne pouvons vous fournir le total de ristourne en mode hors-ligne.' });
      }
    },
    toggleCancelModal() {
      this.showCancelModal = !this.showCancelModal;
    },
  },
  computed: {
    isOnline() {
      return this.$store.state.isOnline;
    },
    customer() {
      return this.$store.state.workingCustomer;
    },
    orderedArticles() {
      const orderedArticles = [];
      this.localOrderCommand.orderLines.forEach((item) => {
        if (item.qtyWeek1 || item.qtyWeek2 || item.qtyWeek3 || item.qtyWeek4 || item.qtyWeek5 || item.qtyWeek6) {
          orderedArticles.push(item);
        }
      });
      return orderedArticles;
    },
    qtyWeek1() {
      let total = 0;
      this.localOrderCommand.orderLines.forEach((item) => {
        total += item.qtyWeek1 ? parseInt(item.qtyWeek1, 10) : 0;
      });
      return total;
    },
    qtyWeek2() {
      let total = 0;
      this.localOrderCommand.orderLines.forEach((item) => {
        total += item.qtyWeek2 ? parseInt(item.qtyWeek2, 10) : 0;
      });
      return total;
    },
    qtyWeek3() {
      let total = 0;
      this.localOrderCommand.orderLines.forEach((item) => {
        total += item.qtyWeek3 ? parseInt(item.qtyWeek3, 10) : 0;
      });
      return total;
    },
    qtyWeek4() {
      let total = 0;
      this.localOrderCommand.orderLines.forEach((item) => {
        total += item.qtyWeek4 ? parseInt(item.qtyWeek4, 10) : 0;
      });
      return total;
    },
    qtyWeek5() {
      let total = 0;
      this.localOrderCommand.orderLines.forEach((item) => {
        total += item.qtyWeek5 ? parseInt(item.qtyWeek5, 10) : 0;
      });
      return total;
    },
    qtyWeek6() {
      let total = 0;
      this.localOrderCommand.orderLines.forEach((item) => {
        total += item.qtyWeek6 ? parseInt(item.qtyWeek6, 10) : 0;
      });
      return total;
    },
    qtyTotal() {
      return this.qtyWeek1 + this.qtyWeek2 + this.qtyWeek3 + this.qtyWeek4 + this.qtyWeek5 + this.qtyWeek6;
    },
  },
  created() {
    this.localOrderCommand.signature = null;
    this.formatedCommand = JSON.parse(JSON.stringify(this.localOrderCommand));
    this.formatedCommand.orderLines.forEach((article) => {
      article.fkArticle = article.articleId;
      delete article.articleId;
      delete article.ristourne;
      delete article.name;
      article.qtyWeek1 = article.qtyWeek1 ? article.qtyWeek1 : 0;
      article.qtyWeek2 = article.qtyWeek2 ? article.qtyWeek2 : 0;
      article.qtyWeek3 = article.qtyWeek3 ? article.qtyWeek3 : 0;
      article.qtyWeek4 = article.qtyWeek4 ? article.qtyWeek4 : 0;
      article.qtyWeek5 = article.qtyWeek5 ? article.qtyWeek5 : 0;
      article.qtyWeek6 = article.qtyWeek6 ? article.qtyWeek6 : 0;
    });
    this.getTotalRistourne();
  },
  mounted() {
    this.canvas = document.querySelector('#draw');
    this.canvas.setAttribute('width', this.canvas.offsetWidth);
    this.canvas.setAttribute('height', this.canvas.offsetHeight);
    this.ctx = this.canvas.getContext('2d');
    this.ctxX = this.canvas.getBoundingClientRect().left;
    this.ctxY = this.canvas.getBoundingClientRect().top;
    this.ctx.strokeStyle = '#212121';
    this.ctx.lineJoin = 'round';
    this.ctx.lineCap = 'round';
    this.ctx.lineWidth = 5;

    this.canvas.addEventListener('mousedown', this.drawInit);
    this.canvas.addEventListener('touchstart', this.drawInitTouch);
    this.canvas.addEventListener('mousemove', this.draw);
    this.canvas.addEventListener('touchmove', this.drawTouch);
    this.canvas.addEventListener('touchend', this.stopDraw);
    this.canvas.addEventListener('mouseup', this.stopDraw);
    this.canvas.addEventListener('mouseout', this.stopDraw);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'new-order' && !this.backFromApp) {
      to.params.editOrder = this.localOrderCommand;
      next();
    } else {
      next();
    }
  },
};
</script>
