<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <div class="row mb-3">
      <div class="col mt-4 mb-2">
        <h2 class="mb-0">Tableau de bord</h2>
        <span class="text-muted">Administration</span>
      </div>
      <div class="col-auto d-flex align-items-center mt-4">
        <button class="btn btn-danger btn-sm" @click="toggleModal(true)">Réinitialiser la base de données</button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 col-sm-6 col-xl-2 mb-4 mb-xl-0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Fournisseurs</h5>
            <p class="card-text">
              <template v-if="statUpload === 'fournisseurs'">
                <span class="loader-sm my-4">&nbsp;</span>
              </template>
              <template v-else>
                <span v-if="statsQty" class="display-4">{{statsQty.find(el => el.tbl === 'Fournisseurs').qty}}</span>
                <span v-else class="text-muted">Récupération des données...</span>
              </template>
            </p>
          </div>
          <div class="card-footer">
            <div class="form-group m-0">
              <label class="card-link color-cordier m-0 file-input--invisible" for="fournisseurs">Importer</label>
              <input type="file" class="d-none" multiple id="fournisseurs" @change="getFileData($event)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-2 mb-4 mb-xl-0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Articles</h5>
            <p class="card-text">
              <template v-if="statUpload === 'articles'">
                <span class="loader-sm my-4">&nbsp;</span>
              </template>
              <template v-else>
                <span v-if="statsQty" class="display-4">{{statsQty.find(el => el.tbl === 'Articles').qty}}</span>
                <span v-else class="text-muted">Récupération des données...</span>
              </template>
            </p>
          </div>
          <div class="card-footer">
            <div class="form-group m-0">
              <label class="card-link color-cordier m-0 file-input--invisible" for="articles">Importer</label>
              <input type="file" class="d-none" multiple id="articles" @change="getFileData($event)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-2 mb-4 mb-xl-0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Visiteurs</h5>
            <p class="card-text">
              <template v-if="statUpload === 'visiteurs'">
                <span class="loader-sm my-4">&nbsp;</span>
              </template>
              <template v-else>
                <span v-if="statsQty" class="display-4">{{statsQty.find(el => el.tbl === 'Visiteurs').qty}}</span>
                <span v-else class="text-muted">Récupération des données...</span>
              </template>
            </p>
          </div>
          <div class="card-footer">
            <div class="form-group m-0">
              <label class="card-link color-cordier m-0 file-input--invisible" for="visiteurs">Importer</label>
              <input type="file" class="d-none" multiple id="visiteurs" @change="getFileData($event)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-2 mb-4 mb-xl-0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Commandes</h5>
            <p class="card-text">
              <span v-if="statsQty" class="display-4">{{statsQty.find(el => el.tbl === 'Commandes').qty}}</span>
              <span v-else class="text-muted">En attente des données...</span>
            </p>
          </div>
          <div class="card-footer">
            <router-link to="/admin-exports" class="card-link color-cordier">Exporter</router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-4 mb-4 mb-xl-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Configuration</h5>
              <div class="row">
                <div class="col-12">
                  <form action="#" @submit.prevent="setForwardEmail()">
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input" id="emailrouting" v-model="forwarding.isForwarded" @change="defineIfForward()" :disabled="!isOnline">
                      <label class="form-check-label" for="emailrouting">Dévier les emails</label>
                    </div>
                    <div v-if="forwarding.isForwarded" class="form-group mb-1">
                      <label for="routingEmail">Email de déviation</label>
                      <div class="input-group">
                        <input type="email" class="form-control" id="routingEmail" v-model="forwarding.forwardEmail" :disabled="!isOnline" />
                        <div class="input-group-append">
                          <button class="btn btn-danger bg-cordier" type="submit">
                            Valider
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Evolution des commandes</h5>
            <div class="row">
              <div class="col-auto">
                <input type="date" class="form-control" :value="date" @change="updateGraph($event.target.value)" />
              </div>
            </div>
            <canvas id="sales" width="568" height="320"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">

    </div>
    <div v-if="showModal" class="overlay" @click.self="toggleModal(false)">
      <div class="modal rounded p-4">
        <div class="row">
          <div class="col">
            <span class="h3 mb-3 d-block">Êtes-vous sûr ?</span>
            <p class="mb-3 d-block">Cela supprimera l'ensemble des données suivantes:</p>
            <ul>
              <li><strong>Tous les fournisseurs</strong></li>
              <li><strong>Tous les articles</strong></li>
              <li><strong>Tous les visiteurs</strong></li>
              <li><strong>Toutes les commandes</strong></li>
            </ul>
            <div class="form-group">
              <label class="form-group-label">Tapez "DELETE" dans le champs suivant pour confirmer</label>
              <input class="form-control" type="text" pattern="DELETE" v-model="deleteConfirm"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="btn btn-sm btn-secondary bg-dark mr-3" @click.self="toggleModal(false)">Annuler</button>
            <button class="btn btn-sm btn-danger" :disabled="deleteConfirm !== 'DELETE'" @click="resetDB()"><span v-if="!isResettingDB">Supprimer les données</span><span v-else class="loader-sm">&nbsp;</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';
import dayjs from 'dayjs';
import AppService from '../../shared/services/AppService';

export default {
  name: 'Admin',
  data() {
    return {
      canvas: null,
      salesChart: null,
      files: [],
      statsQty: null,
      date: dayjs().format('YYYY-MM-DD'),
      statUpload: null,
      showModal: false,
      deleteConfirm: null,
      isResettingDB: false,
      forwarding: {
        isForwarded: false,
        forwardEmail: null,
      },
    };
  },
  methods: {
    computeCanvasSize() {
      this.canvas.style.width = '100%';
      const width = window.getComputedStyle(this.canvas).getPropertyValue('width');

      this.canvas.removeAttribute('style');
      this.canvas.setAttribute('width', width);
      this.canvas.setAttribute('height', `${(parseInt(width.replace('px', ''), 10) / 4).toString()}px`);
    },
    async updateGraph(date = dayjs().format('YYYY-MM-DD')) {
      this.date = date;
      let hours = null;
      let dataEvolve = null;
      let dataPerHour = null;
      if (this.isOnline) {
        try {
          const response = await AppService.getOrderStat(this.date);

          if (response.status === 200) {
            hours = response.data.reduce((list, el) => {
              list.push(`${el.hour}:00`);
              return list;
            }, []);
            dataPerHour = response.data.reduce((list, el) => {
              list.push(el.orderQty);
              return list;
            }, []);
            dataEvolve = response.data.reduce((list, el) => {
              let qty = list[list.length - 1] ? list[list.length - 1] : 0;
              list.push(qty += el.orderQty);
              return list;
            }, []);
          } else {
            this.pushToast('erreur', 'Une erreur s\'est produite lors de la récupération des statistiques');
          }
        } catch (err) {
          this.pushToast('erreur', 'Une erreur s\'est produite lors de la récupération des statistiques');
        }
      } else {
        this.pushToast('attention', 'Nous ne pouvons pas vous fournir les statistiques en mode hors-ligne');
      }
      this.salesChart = new Chart(this.canvas.getContext('2d'), {
        type: 'bar',
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                type: 'linear',
                ticks: {
                  beginAtZero: true,
                },
                display: true,
                position: 'left',
                id: 'leftAxe',
              },
              {
                type: 'linear',
                ticks: {
                  beginAtZero: true,
                },
                display: true,
                position: 'right',
                id: 'rightAxe',
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
        },
        data: {
          labels: hours,
          datasets: [
            {
              label: 'Commandes envoyées par heure',
              data: dataPerHour,
              backgroundColor: 'rgba(56, 55, 56, .5)',
              yAxisID: 'leftAxe',
            },
            {
              label: 'Evolution des commandes',
              backgroundColor: 'rgba(196, 40, 32, 0)',
              borderColor: 'rgba(196, 40, 32, 1)',
              lineTension: '0.0',
              fill: false,
              data: dataEvolve,
              type: 'line',
              yAxisID: 'rightAxe',
            },
          ],
        },
      });
    },
    getFileData(event) {
      const { files } = event.target;
      let unsupported = 0;
      if (this.isOnline) {
        for (const file of files) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            const data = {
              fileName: file.name,
              fileData: this.convertStringData(reader.result),
            };
            this.files.push(data);
            if (this.files.length === files.length - unsupported) {
              this.uploadFiles(event.target.id);
            }
          });

          if (!['application/vnd.ms-excel', 'text/csv'].includes(file.type)) {
            unsupported += 1;
            this.pushToast('erreur', `Le fichier suivant n'a pas été uploadé car il n'est pas au bon format:<br><span class="font-weight-bold">${file.name}</span>`);
          } else {
            reader.readAsDataURL(file);
          }
        }
      } else {
        this.pushToast('attention', 'Impossible d\'uploader des documents en mode hors-ligne');
      }
    },
    convertStringData(inputString) {
      return inputString.replace(/data:(.+);base64,/, '');
    },
    async uploadFiles(targetId) {
      this.statUpload = targetId;
      try {
        const response = await AppService.uploadFiles(this.files, targetId);
        let responseStr = '';
        if (response.status === 200) {
          response.data.forEach((str) => {
            responseStr += `${str}<br>`;
          });
          this.pushToast('succès', responseStr);
          this.getStatsQty();
        } else if (response.status === 410) {
          response.data.forEach((str) => {
            responseStr += `${str}<br>`;
          });
          this.pushToast('erreur', responseStr);
        } else {
          this.pushToast('erreur', 'Une erreur inattendue s\'est produite lors de l\'upload');
        }
      } catch (err) {
        this.pushToast('erreur', 'Le serveur a rencontré une erreur lors de l\'upload');
      }
      this.statUpload = null;
      document.querySelector(`#${targetId}`).value = null;
      this.files = [];
    },
    pushToast(status, body) {
      this.$emit('onToast', { status, body });
    },
    async getStatsQty() {
      if (this.isOnline) {
        try {
          const response = await AppService.getStatQty();

          if (response.status === 200) {
            this.statsQty = response.data;
          } else {
            this.statsQty = null;
            this.pushToast('erreur', 'Une erreur s\'est produite lors de la récupération des totaux');
          }
        } catch (err) {
          this.statsQty = null;
          this.pushToast('erreur', 'Une erreur s\'est produite lors de la récupération des totaux');
        }
      } else {
        this.pushToast('attention', 'Nous ne pouvons pas vous fournir les totaux en mode hors-ligne');
      }
    },
    toggleModal(show) {
      this.showModal = show;
      if (!show) {
        this.deleteConfirm = null;
      }
    },
    async resetDB() {
      if (this.isOnline) {
        this.isResettingDB = true;
        try {
          const response = await AppService.resetDB();

          if (response.status === 200) {
            this.pushToast('succès', 'La base de données a été réinitialisée !');
          } else {
            this.pushToast('erreur', 'Une erreur s\'est produite lors de réinitialisation de la base de données');
          }
        } catch (err) {
          this.pushToast('erreur', 'Une erreur s\'est produite lors de réinitialisation de la base de données');
        }
      } else {
        this.pushToast('attention', 'Impossible de réinitialiser la base de données en mode hors-ligne');
      }
      this.isResettingDB = false;
      this.toggleModal(false);
      this.getStatsQty();
    },
    async getForwardState() {
      if (this.isOnline) {
        try {
          const response = await AppService.getParamEmail();

          if (response.status === 200) {
            this.forwarding.isForwarded = response.data.isForwarded;
            this.forwarding.forwardEmail = response.data.forwardEmail;
          } else {
            this.forwarding.isForwarded = false;
            this.forwarding.forwardEmail = null;
            this.pushToast('erreur', 'Une erreur s\'est produite lors de la récupération de l\'email de transfert');
          }
        } catch (err) {
          this.forwarding.isForwarded = false;
          this.forwarding.forwardEmail = null;
          this.pushToast('erreur', 'Une erreur s\'est produite lors de la récupération de l\'email de transfert');
        }
      } else {
        this.pushToast('attention', 'Nous ne pouvons pas récupérer l\'email de transfert en mode hors-ligne');
      }
    },
    async setForwardEmail() {
      if (this.isOnline) {
        try {
          const response = await AppService.updateParamEmail(this.forwarding);

          if (response.status === 200) {
            this.pushToast('succès', 'Modification de l\'email de transfert effectuée');
          } else {
            this.pushToast('erreur', 'Une erreur s\'est produite lors de la modification de l\'email de transfert');
          }
        } catch (err) {
          this.pushToast('erreur', 'Une erreur s\'est produite lors de la modification de l\'email de transfert');
        }
      }
    },
    defineIfForward() {
      if (this.forwarding.isForwarded === false) {
        this.forwarding.forwardEmail = null;
        this.setForwardEmail();
      }
    },
  },
  computed: {
    isOnline() {
      return this.$store.state.isOnline;
    },
  },
  watch: {
    statsQty(newVal) {
      if (newVal) {
        setTimeout(() => this.getStatsQty(), 60000);
      }
    },
    isOnline(val) {
      if (val) {
        this.getStatsQty();
        this.updateGraph();
      }
    },
  },
  created() {
    this.getStatsQty();
    this.getForwardState();
  },
  async mounted() {
    this.canvas = document.querySelector('#sales');
    await this.computeCanvasSize();
    this.updateGraph();
  },
};
</script>
