<template>
  <div class="container-fluid p-interface p-interface--bottom">
    <template v-if="isLoading">
      <div class="row mt-5">
        <div class="col d-flex align-item-center justify-content-center mt-5">
          <span class="loader mt-5">Chargement des données</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mb-3">
        <div class="col-12 mt-4 mb-2">
          <h2 class="mb-0">Commandes</h2>
        </div>
        <div class="col-12 col-sm-6">
          <form action="#" @submit.prevent="getFilteredOrders()">
            <div class="input-group mb-3">
              <input type="search" class="form-control" aria-label="Recherche" v-model="search" />
              <div class="input-group-append">
                <button class="btn btn-danger bg-cordier" type="submit">
                  Rechercher
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 col-sm-6 text-right">
          <button class="btn btn-danger bg-cordier" @click="exportOrders()"><span v-if="!isExportingOrders">Exporter commandes</span><span v-else class="loader-sm">&nbsp;</span></button>
        </div>
      </div>
      <template v-if="orders.length > 0">
        <div v-if="filteredOrders.length > 0" class="row">
          <div class="col-12">
            <table class="table table-striped table-hover">
              <thead>
                <th class="small font-weight-bold bg-light">Fournisseur</th>
                <th class="small font-weight-bold bg-light">Visiteur</th>
                <th class="small font-weight-bold bg-light">ID visiteur</th>
                <th class="small font-weight-bold bg-light">Nombre d'articles</th>
                <th class="small font-weight-bold bg-light">Date - Heure</th>
                <th v-if="isLoadingOrderDetail" class="small font-weight-bold bg-light"></th>
              </thead>
              <tbody>
                <template v-for="item of filteredOrders">
                  <tr v-if="true" :key="item.orderId" :class="{ 'bg-info text-white': (orderDetail && orderDetail.orderId === item.orderId) }" @click="(orderDetail && orderDetail.orderId === item.orderId) ? orderDetail = null : getOrderDetail(item.orderId, item.supplierId)">
                    <td class="small py-2">{{item.supplier}}</td>
                    <td class="small py-2">{{item.visitor}}</td>
                    <td class="small py-2">{{item.visitorId}}</td>
                    <td class="small py-2">{{item.orderQty}}</td>
                    <td class="small py-2">{{item.createdOn}}</td>
                    <td v-if="isLoadingOrderDetail && orderToFetch === item.orderId" class="small py-2 loader-sm"></td>
                  </tr>
                  <tr :key="item.orderId + 1" v-if="orderDetail && orderDetail.orderId === item.orderId">
                    <td colspan="5" class="p-0">
                      <table class="table table-striped">
                        <thead>
                          <th class="small font-weight-bold bg-light">Référence</th>
                          <th class="small font-weight-bold bg-light">Ristourne</th>
                          <th class="small font-weight-bold bg-light weeks_cell">42</th>
                          <th class="small font-weight-bold bg-light weeks_cell">43</th>
                          <th class="small font-weight-bold bg-light weeks_cell">44</th>
                          <th class="small font-weight-bold bg-light weeks_cell">45</th>
                          <th class="small font-weight-bold bg-light weeks_cell">46</th>
                          <th class="small font-weight-bold bg-light weeks_cell">49</th>
                        </thead>
                        <tbody>
                          <tr v-for="article of orderedArticles" :key="article.articleId">
                            <td class="small">{{article.name}}</td>
                            <td class="small">{{article.ristourne | floatToString('€')}}</td>
                            <td v-for="i of 6" :key="i" class="small weeks_cell">{{article[`qtyWeek${i}`] ? article[`qtyWeek${i}`] : 0}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center text-muted">
            <p>Aucune commande ne correspond à votre recherche</p>
          </div>
        </div>
      </template>
      <template v-if="orders.length === 0 && !error">
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>Il semblerait que cette liste soit vide.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12 text-center text-muted">
            <p>{{error}}</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import AppService from '../../shared/services/AppService';
import OrderCommand from '../customers/commands/NewOrder-command';

export default {
  name: 'Orders',
  data() {
    return {
      search: null,
      orders: [],
      error: null,
      isLoading: false,
      isLoadingOrderDetail: false,
      orderToFetch: null,
      orderDetail: null,
      filteredOrders: [],
      isExportingOrders: false,
    };
  },
  methods: {
    async getOrders() {
      this.isLoading = true;
      if (this.isOnline) {
        try {
          const response = await AppService.getOrderList();

          if (response.status === 200) {
            this.orders = response.data;
            this.error = null;
          } else {
            this.orders = [];
            this.error = 'Une erreur est survenue lors de la récupération des commandes';
          }
        } catch (err) {
          this.orders = [];
          this.error = 'Une erreur innatendue est survenue avec notre serveur';
        }
      } else {
        this.orders = [];
        this.error = 'Impossible de récupérer les commandes en mode hors-ligne';
      }
      this.isLoading = false;
    },
    async fetchArticles(supplierId) {
      const articlesResponse = await AppService.getArticles(supplierId);
      if (articlesResponse.status === 200) {
        this.$store.commit('setArticles', { data: articlesResponse.data, date: articlesResponse.headers.date });
      } else {
        throw new Error();
      }
    },
    async getOrderDetail(orderId, supplierId) {
      this.orderToFetch = orderId;
      this.isLoadingOrderDetail = true;
      this.orderDetail = null;

      try {
        await this.fetchArticles(supplierId);
        const response = await AppService.getOrder(orderId);

        if (response.status === 200) {
          const order = response.data;
          this.orderDetail = new OrderCommand();
          this.orderDetail.orderId = order.orderId;
          this.orderDetail.orderLines.forEach((line) => {
            const article = order.orderLines.find((el) => el.fkArticle === line.articleId);
            if (article) {
              line.qtyWeek1 = article.qtyWeek1;
              line.qtyWeek2 = article.qtyWeek2;
              line.qtyWeek3 = article.qtyWeek3;
              line.qtyWeek4 = article.qtyWeek4;
              line.qtyWeek5 = article.qtyWeek5;
              line.qtyWeek6 = article.qtyWeek6;
            }
          });
        } else {
          this.$emit('onToast', { status: 'erreur', body: 'Impossible de récupérer le détail de la commande' });
        }
      } catch (err) {
        this.$emit('onToast', { status: 'erreur', body: 'Impossible de récupérer le détail de la commande' });
      }
      this.isLoadingOrderDetail = false;
      this.orderToFetch = null;
    },
    getFilteredOrders() {
      this.filteredOrders = this.search ? this.orders.filter((el) => el.supplier.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.visitor.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.orders;
    },
    async exportOrders() {
      if (this.isOnline) {
        this.isExportingOrders = true;
        try {
          const response = await AppService.exportOrders();

          if (response.status === 200) {
            const bytes = await this.base64ToArrayBuffer(response.data);
            const blob = new Blob([bytes], { type: 'application/zip' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'orders.zip';
            link.click();
          } else {
            this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de l\'exportation des commandes' });
          }
        } catch (err) {
          this.$emit('onToast', { status: 'erreur', body: 'Une erreur s\'est produite lors de l\'exportation des commandes' });
        }
      } else {
        this.$emit('onToast', { status: 'attention', body: 'Impossible d\'exporter les commandes en mode hors-ligne' });
      }
      this.isExportingOrders = false;
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i += 1) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
  },
  computed: {
    // filteredOrders() {
    //   return this.search ? this.orders.filter(el => el.supplier.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.visitor.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.orders;
    // },
    isOnline() {
      return this.$store.state.isOnline;
    },
    orderedArticles() {
      if (!this.orderDetail) {
        return null;
      }
      const orderedArticles = [];
      this.orderDetail.orderLines.forEach((item) => {
        if (item.qtyWeek1 || item.qtyWeek2 || item.qtyWeek3 || item.qtyWeek4 || item.qtyWeek5 || item.qtyWeek6) {
          orderedArticles.push(item);
        }
      });
      return orderedArticles;
    },
  },
  async created() {
    await this.getOrders();
    this.getFilteredOrders();
  },
};
</script>
